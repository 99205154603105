import React, { useState } from "react";
import "./Landing.css";
import { Link } from "react-router-dom";
import homeBanner1 from "../../image/home_banner/admissionbanner1.jpg";
import homeBanner2 from "../../image/home_banner/admissionbanner2.jpg";
import homeBanner3 from "../../image/home_banner/admissionbanner3.jpg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Landing({ video, cover }) {
  const [isMuted, setIsMuted] = useState(true);

  return video ? (
    <div className="">
      {/* <ReactPlayer
        url={campus}
        loop={true}
        muted={isMuted}
        playing={true}
        controls={false}
        width="100%"
        className="landing_video"
      />*/}
      <div id="carouselExampleIndicators" className="carousel slide margin8">
        <div>
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
            <Link to="/admissions">
              <img
                src={homeBanner1}
                width="100%"
              />
              
              </Link>
            </div>
            <div className="carousel-item">
            <Link to="/admissions">
              <img
                src={homeBanner2}
                width="100%"
              />
              </Link>
            </div>
            <div className="carousel-item">
            <Link to="/admissions">
              <img
                src={homeBanner3}
                width="100%"
              />
              </Link>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>         
        </div>
      </div>
    </div>
  ) : (
    <div className="cover_img_cont">
      <img src={cover} alt="images" className="cover_img cmt" />
    </div>
  );
}

export default Landing;
